import styled from 'styled-components';
import { BlobMetricSearchPanelWrapper } from '../../BlobMetrics/BlobMetricSearchPanel/style';

export const AuditHistorySearchPanelWrapper = styled(BlobMetricSearchPanelWrapper)`
    padding: 0;
    margin: 0 7px;

    .ant-btn-icon-only, .ant-picker {
        height: 41px;
    }

    .ant-select {
        border-left: none;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 40px;
    }

    @media screen and (min-width: 1600px) {
        .ant-btn-icon-only, .ant-picker {
            height: 50px;
        }

        .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
            line-height: 49px;
        }
    }
`;
