import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ConfigurationListTable from '../../../ConfigurationListTable';
import textConstants from '../../../../../constants/textConstants';
import { errorNotification } from '../../../../common/Notification';
import routeConstants from '../../../../../constants/routeConstants';
import AddEditConfigDrawer from '../../../Drawer/AddConfigDrawer';
import ReplayConfigForm from '../../../UpsertForms/Admin/ReplayConfig';
import { deleteReplayConfig, getReplayConfigList, saveReplayConfig } from '../../../../../redux/actions/Admin/replayConfigActions';
import { REPLAY_CONFIG_COLUMNS } from '../../../../../constants/TableColumns/Admin/ReplayConfigColumns';
import { checkIfExistsInArray, getEditDataChanges } from '../../../../../helpers/commonHelper';
import type { AdminConfigData, SelectedRows } from '../../../../../constants/Interfaces/AdminConfigListTypes';
import { REPLAY_ID } from '../../../../../constants/KeyLabels/commonKeyConstants';
import { cloneDeep } from 'lodash';

interface ConfigListProps {
  isFromApplicationConfig?: boolean
  applicationName?: string
}

const ReplayConfigList: FC<ConfigListProps> = ({ applicationName, isFromApplicationConfig }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const replayConfigList = useSelector((state: any) => state.replayConfig.replayConfigList);

  const [isAddEditDrawerOpen, setAddEditDrawerVisibility] = useState<boolean>(false);
  const [isMultipleModify, setMultipleModifyState] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<SelectedRows>([]);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const handleItemNavigation = (currentIndex: number): void => {
    setActiveItemIndex(currentIndex);
  }

  const toggleAddEditDrawerVisibility = (isOpen: boolean): void => {
    setAddEditDrawerVisibility(isOpen);
  }

  const onAddEditDrawerClose = (): void => {
    toggleAddEditDrawerVisibility(false);
    setMultipleModifyState(false);
    setActiveItemIndex(0);
  }

  const onCreateBtnClick = (): void => {
    setSelectedRows([]);
    toggleAddEditDrawerVisibility(true);
  }

  const onMultipleModify = (configList: SelectedRows): void => {
    setMultipleModifyState(true);
    setSelectedRows(configList);
    setAddEditDrawerVisibility(true);
  }

  const onReplayConfigSave = (data: AdminConfigData, isClosable?: boolean): void => {
    if (!isMultipleModify && !checkIfExistsInArray(replayConfigList, data, ['applicationName', 'replayMode'])) {
      dispatch(saveReplayConfig(data, isClosable ? toggleAddEditDrawerVisibility(false) : null, true));
    } else if (isMultipleModify) {
      const updatedPayload = getEditDataChanges(selectedRows[activeItemIndex], data);
      dispatch(saveReplayConfig(updatedPayload, null, false));
    } else {
      errorNotification(textConstants.REPLAY_CONFIG_ALREADY_EXIST_MESSAGE);
    }
  }

  const showDetailPageHandler = (rowData: AdminConfigData, isEditMode: boolean = true): void => {
    history.push({
      pathname: routeConstants.ADMIN_REPLAY_CONFIG_DETAIL,
      state: {
        isEditable: isEditMode,
        data: rowData,
        isFromApplicationConfig
      }
    });
  }

  useEffect(() => {
    if (isMultipleModify) {
      const updatedConfigId = selectedRows[activeItemIndex][REPLAY_ID];

      const updatedConfigData = replayConfigList.find((item: any) => item[REPLAY_ID] === updatedConfigId);

      const clonedSelectedRows = cloneDeep(selectedRows);
      clonedSelectedRows[activeItemIndex] = updatedConfigData;
      setSelectedRows(clonedSelectedRows);
    }
  }, [replayConfigList, isMultipleModify])

  return (
    <>
      <ConfigurationListTable
        columnDefs={REPLAY_CONFIG_COLUMNS}
        getConfigList={getReplayConfigList}
        onConfigDelete={deleteReplayConfig}
        gridName={textConstants.REPLAY_CONFIG_PAGE_TITLE}
        gridItemId={REPLAY_ID}
        onEdit={showDetailPageHandler}
        onRowDoubleClicked={(params: { data: AdminConfigData }) => showDetailPageHandler(params.data, false)}
        filterDataByApplicationName={applicationName}
        onCreate={onCreateBtnClick}
        onMultipleModify={onMultipleModify}
        listName='replayConfig'
        rowSelection="multiple"
        rowMultiSelectWithClick
        hasFilter={!applicationName}
        // hasAuditSummary
        hasRowAction
        hasCreate
        hideRowCheckbox
      />
      {isAddEditDrawerOpen && (
        <AddEditConfigDrawer
          isOpen={isAddEditDrawerOpen}
          dataTestId='replayConfigAddDrawer'
          title={`${isMultipleModify ? 'Modify' : 'New'} ${textConstants.REPLAY_CONFIG_ADD_EDIT_DRAWER_TITLE}`}
          onCancel={onAddEditDrawerClose}
          handleItemNavigation={handleItemNavigation}
          currentItemIndex={activeItemIndex}
          selectedItemsCount={selectedRows.length - 1}
        >
          <ReplayConfigForm
            data={{
              applicationName,
              ...(isMultipleModify && { ...selectedRows[activeItemIndex] })
            }}
            onCancel={onAddEditDrawerClose}
            onSave={onReplayConfigSave}
            isEditable={isMultipleModify}
            isDrawerView
          />
        </AddEditConfigDrawer>
      )}
    </>
  );
};

ReplayConfigList.defaultProps = {
  isFromApplicationConfig: false
}

ReplayConfigList.propTypes = {
  applicationName: PropTypes.string,
  isFromApplicationConfig: PropTypes.bool
}

export default ReplayConfigList;
