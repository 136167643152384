import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import SummaryApplicationDetail from './SummaryApplicationDetail';
import { updateApplicationDetails } from '../../../../../redux/actions/Admin/applicationConfigActions';
import { useDispatch } from 'react-redux';
import { SummaryAppDetailTopWrapper, SummaryAppDetailWrapper, SummaryMainWrapper } from './style';
import ApplicationConfigForm from '../../../UpsertForms/Admin/ApplicationConfig';
import ConfigDetailCardHeader from '../../../ConfigDetailCardHeader';
import { getEditDataChanges } from '../../../../../helpers/commonHelper';

interface componentProps {
  applicationDetailData?: any
  isEditMode?: boolean
}

const SummaryTabContent: FC<componentProps> = ({ applicationDetailData, isEditMode }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isApplicationDetailInEditMode, setApplicationDetailInEditMode] = useState(isEditMode ?? false);
  const [applicationData, setApplicationData] = useState(applicationDetailData);

  const onFormSaveCancelHandler = (): void => {
    if (isEditMode) {
      history.goBack();
    } else {
      setApplicationDetailInEditMode(false);
    }
  }

  const onSaveHandler = (data: any): void => {
    const updatedPayload = getEditDataChanges(applicationDetailData, data);
  
    if (isApplicationDetailInEditMode && updatedPayload) {
      dispatch(updateApplicationDetails(updatedPayload, onFormSaveCancelHandler, false));
      setApplicationData(data);
    } else {
      onFormSaveCancelHandler();
    }
  }

  return (
    <>
      <SummaryMainWrapper data-testid="testid-application-detail-summary-tab">
        <SummaryAppDetailTopWrapper>
          <ConfigDetailCardHeader
            onEdit={() => setApplicationDetailInEditMode(true)}
            isEditEnabled={!isApplicationDetailInEditMode}
            isDeleteEnabled={false}
            title='Application Details'
          />
          <SummaryAppDetailWrapper>
            {
              !isApplicationDetailInEditMode &&
              <SummaryApplicationDetail applicationDetailData={applicationData} />
            }
            {
              isApplicationDetailInEditMode &&
              <ApplicationConfigForm
                applicationDetailData={applicationData}
                isEditable={isApplicationDetailInEditMode}
                onSave={onSaveHandler}
                onCancel={onFormSaveCancelHandler}
              />
            }
          </SummaryAppDetailWrapper>
        </SummaryAppDetailTopWrapper>
      </SummaryMainWrapper>
    </>
  );
};

SummaryTabContent.propTypes = {
  applicationDetailData: PropTypes.object,
  isEditMode: PropTypes.bool
};

export default SummaryTabContent;
