import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationPopup from '../../ConfirmationPopup';
import { DeleteOutlined, HistoryOutlined, FormOutlined } from '@ant-design/icons';
import {
  DeleteIconButton,
  EditIconButton
} from '../../Footer/Buttons.style';
import textConstants from '../../../../constants/textConstants';

interface TableRowActionsProps {
  auditBtnId?: string
  editBtnId?: string
  deleteBtnId?: string
  hasAuditSummary?: boolean
  hasDelete?: boolean
  hasEdit?: boolean
  hasIconOnly?: boolean
  isDeleteDisabled?: boolean
  isEditDisabled?: boolean
  onAuditSummary?: () => void
  onDelete?: any
  onEdit?: () => void
  tableGridName?: string
}

const TableRowActions: React.FC<TableRowActionsProps> = ({
  auditBtnId,
  hasAuditSummary,
  hasDelete,
  hasEdit,
  hasIconOnly,
  isDeleteDisabled,
  isEditDisabled,
  onAuditSummary,
  onDelete,
  onEdit,
  tableGridName,
  editBtnId,
  deleteBtnId
}) => {
  const [isDeleteConfirmationVisible, setDeleteConfirmationVisibility] = useState(false);

  const toggleDeleteConfirmationVisibility = (isVisible: boolean): void => {
    setDeleteConfirmationVisibility(isVisible);
  }

  return (
    <>
        {hasDelete && (
          <DeleteIconButton
            data-testid={`deleteRowBtn-${deleteBtnId}`}
            disabled={isDeleteDisabled}
            icon={<DeleteOutlined />}
            onClick={() => toggleDeleteConfirmationVisibility(true)}
            type="text"
          >
            {!hasIconOnly && 'Delete'}
          </DeleteIconButton>
        )}
        {hasEdit && (
          <EditIconButton
            data-testid={`editRowBtn-${editBtnId}`}
            disabled={isEditDisabled}
            icon={<FormOutlined />}
            onClick={onEdit}
            type="text"
          >
            {!hasIconOnly && 'Edit'}
          </EditIconButton>
        )}
        {hasAuditSummary && (
          <DeleteIconButton
            data-testid={`auditRowBtn-${auditBtnId}`}
            disabled={isDeleteDisabled}
            icon={<HistoryOutlined />}
            onClick={onAuditSummary}
            type="text"
          >
            {!hasIconOnly && 'History'}
          </DeleteIconButton>
        )}
        {isDeleteConfirmationVisible && (
            <ConfirmationPopup
                confirmationTitle={`Delete ${tableGridName}`}
                confirmBtnLabel={textConstants.DELETE_CONFIRMATION_POPUP_CONFIRM_BUTTON_TEXT}
                cancelBtnLabel={textConstants.DELETE_CONFIRMATION_POPUP_CANCEL_BUTTON_TEXT}
                isConfirmOpen={isDeleteConfirmationVisible}
                message={`${textConstants.DELETE_CONFIRMATION_POPUP_CONTENT_TEXT} ${tableGridName}?`}
                onConfirmHandler={() => {
                  onDelete();
                  toggleDeleteConfirmationVisibility(false);
                }}
                onCancelHandler={() => toggleDeleteConfirmationVisibility(false)}
            />
        )}
    </>
  );
};

TableRowActions.defaultProps = {
  auditBtnId: 'config',
  deleteBtnId: 'default',
  editBtnId: 'default',
  hasAuditSummary: false,
  hasIconOnly: true,
  tableGridName: 'Config'
}

TableRowActions.propTypes = {
  auditBtnId: PropTypes.string,
  deleteBtnId: PropTypes.string,
  editBtnId: PropTypes.string,
  hasAuditSummary: PropTypes.bool,
  hasDelete: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasIconOnly: PropTypes.bool,
  isDeleteDisabled: PropTypes.bool,
  isEditDisabled: PropTypes.bool,
  onEdit: PropTypes.func,
  onAuditSummary: PropTypes.func,
  onDelete: PropTypes.func,
  tableGridName: PropTypes.string
};

export default TableRowActions;
