import React, { useState } from 'react';
import type { FC } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AdminMainLayoutWrapper } from '../../../../assets/styles/commonStyled';
import textConstants from '../../../../constants/textConstants';
import ExceptionConfigSummary from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/ExceptionConfig/ExceptionConfigSummary';
import { saveExceptionConfig, deleteExceptionConfig } from '../../../../redux/actions/Admin/exceptionConfigActions';
import { ConfigDetailMainWrapper, ConfigDetailWrapper } from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/style';
import ConfigDetailCardHeader from '../../../../components/AdditionalComponents/ConfigDetailCardHeader';
import ExceptionConfigForm from '../../../../components/AdditionalComponents/UpsertForms/Admin/ExceptionConfig';
import AdminDetailBreadCrumb from '../../../../components/common/BreadCrumb/AdminDetailBreadCrumb';
import { getEditDataChanges } from '../../../../helpers/commonHelper';

const ExceptionConfigDetail: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { data, isEditable, isFromApplicationConfig } = location?.state as { isEditable?: boolean, data: any, isFromApplicationConfig?: boolean } ?? {};

  const [isConfigDetailInEditMode, setConfigDetailInEditMode] = useState(isEditable);
  const [configData, setConfigData] = useState(data);

  const onFormSaveCancelHandler = (): void => {
    if (isEditable) {
      history.goBack();
    } else {
      setConfigDetailInEditMode(false);
    }
  }

  const onExceptionConfigSaveHandler = (formData: any): void => {
    const updatedPayload = getEditDataChanges(configData, formData);

    if (updatedPayload) {
      setConfigData(formData);
      dispatch(saveExceptionConfig(updatedPayload, onFormSaveCancelHandler));
    } else {
      onFormSaveCancelHandler();
    }
  }

  const onExceptionConfigDeleteHandler = (): void => {
    dispatch(deleteExceptionConfig([data], history.goBack));
  }

  return (
    <AdminMainLayoutWrapper data-testid="exceptionConfigDetail">
      <AdminDetailBreadCrumb
        isFromApplicationConfig={isFromApplicationConfig}
        mainPageText={textConstants.EXCEPTION_CONFIG_PAGE_TITLE}
        currentPageText={data?.applicationName}
      />
      <ConfigDetailMainWrapper hasBottomGap={isConfigDetailInEditMode}>
        <ConfigDetailWrapper>
          <ConfigDetailCardHeader
            onDelete={onExceptionConfigDeleteHandler}
            onEdit={() => setConfigDetailInEditMode(true)}
            isEditEnabled={!isConfigDetailInEditMode}
            title={textConstants.EXCEPTION_CONFIG_DETAIL_PAGE_TITLE}
            configName={textConstants.EXCEPTION_CONFIG_PAGE_TITLE}
          />
          {isConfigDetailInEditMode && (
            <ExceptionConfigForm
              data={configData}
              onSave={onExceptionConfigSaveHandler}
              onCancel={onFormSaveCancelHandler}
            />
          )}
          {!isConfigDetailInEditMode && (
            <ExceptionConfigSummary
              data={configData}
            />
          )}
        </ConfigDetailWrapper>
      </ConfigDetailMainWrapper>
    </AdminMainLayoutWrapper>
  );
};

export default ExceptionConfigDetail;
