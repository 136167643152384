import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import type { IRowNode, SelectionChangedEvent } from 'ag-grid-community';
import { ConfigurationListTableWrapper } from '../../../assets/styles/commonStyled';
import CommonTableComponent from '../../common/Table/CommonTable';
import ConfigAuditHistoryDrawer from '../Drawer/ConfigAuditHistoryDrawer';
import TableHeaderActions from '../../common/Table/TableHeaderActions';
import { applicationDataHelper, filterListByApplicationName } from '../../../helpers/applicationDataHelper';
import { ROW_ACTION_COLUMN } from '../../../constants/TableColumns/RowActionColumn';
import { getCachedTableFilterData, setKeyDataInLocalStorage } from '../../../helpers/localStorageHelper';
import { CONFIG_TABLE_SEARCH_FILTER_KEY } from '../../../constants/KeyLabels/commonKeyConstants';
import { filterListByField } from '../../../helpers/commonHelper';

interface ConfigurationListTableProps {
  allowSearchFilterCache?: boolean
  columnDefs: Array<Record<string, any>>
  createBtnClass?: string
  creatBtnLabel?: string
  deleteBtnLabel?: string
  hideRowCheckbox?: boolean
  getConfigList: any
  getRowId?: any
  gridName?: string
  gridRowId?: string
  hasAuditSummary?: boolean
  hasClose?: boolean
  hasCreate?: boolean
  hasCustomAdd?: boolean
  hasCustomDelete?: boolean
  hasDefaultSearch?: boolean
  hasHeaderDelete?: boolean
  hasEdit?: boolean
  hasDelete?: boolean
  hasImport?: boolean
  hasExport?: boolean
  hasFilter?: boolean
  hasRowAction?: boolean
  isDataLoading?: boolean
  listName: string
  onClose?: () => void
  onCreate?: any
  onConfigDelete?: any
  onEdit?: any
  onImport?: any
  onExport?: any
  onMultipleModify?: any
  rowData?: Array<Record<string, object>>
  rowSelection?: string
  rowMultiSelectWithClick?: boolean
  onRowDoubleClicked?: any
  gridItemId?: string
  showResultsCount?: boolean
  showSearchBar?: boolean
  height?: string
  className?: string
  filterBy?: any
  filterDataByApplicationName?: string
  dataFilterFieldConfig?: any
}

const ConfigurationListTable: FC<ConfigurationListTableProps> = ({
  allowSearchFilterCache,
  creatBtnLabel,
  columnDefs,
  dataFilterFieldConfig,
  deleteBtnLabel,
  hideRowCheckbox,
  getConfigList,
  getRowId,
  gridItemId,
  gridName,
  gridRowId,
  hasAuditSummary,
  hasClose,
  hasCreate,
  hasCustomAdd,
  hasCustomDelete,
  hasDefaultSearch,
  hasEdit,
  hasHeaderDelete,
  hasDelete,
  hasExport,
  hasFilter,
  hasImport,
  hasRowAction,
  isDataLoading,
  listName,
  rowData,
  rowSelection,
  rowMultiSelectWithClick,
  onClose,
  onCreate,
  onConfigDelete,
  onExport,
  onEdit,
  onImport,
  onMultipleModify,
  onRowDoubleClicked,
  showResultsCount,
  showSearchBar,
  height,
  className,
  filterDataByApplicationName,
  filterBy,
  createBtnClass
}) => {
  const dispatch = useDispatch();

  const rowListData = useSelector((state: any) => state[listName]?.[`${listName}List`] ?? []);
  const userAccessInfo = useSelector((state: any) => state.userAccess?.userAccessInfo);
  const [isConfigHistoryPopupOpen, setConfigHistoryPopupState] = useState<boolean>(false);
  const [gridApi, setGridApi] = useState<any>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [currentRowCount, setCurrentRowCount] = useState(0);

  const onAuditSummaryBtnHandler = (configId: string): void => {
    setConfigHistoryPopupState(true);
  }

  const onConfigAuditHistoryCancel = (): void => {
    setConfigHistoryPopupState(false);
  }

  const onCreateHandler = (): void => {
    let rowData = [];
    if (!hasCustomAdd) {
      gridApi?.deselectAll();
      onCreate();
    } else {
      if (gridApi?.getSelectedRows().length > 0) {
        rowData = gridApi?.getSelectedRows()
        onCreate(rowData);
      }
    }
  }

  const onDeleteHandler = (data: any): void => {
    let rowData = [];
    if (!hasCustomDelete) {
      dispatch(onConfigDelete([data]));
    } else {
      if (gridApi?.getSelectedRows().length > 0) {
        rowData = gridApi?.getSelectedRows()
        onConfigDelete(rowData);
      }
    }
  }

  const onExportData = (): void => {
    let rowData = [];
    if (gridApi?.getSelectedRows().length > 0) {
      rowData = gridApi?.getSelectedRows()
    } else {
      gridApi?.forEachNode((node: any) => rowData.push(node.data));
    }

    onExport(rowData);
  }

  const onMultipleModifyHandler = (): void => {
    onMultipleModify(selectedRows);
  }

  const filteredDataByField = useMemo(() => {
    if (dataFilterFieldConfig) {
      return filterListByField(rowListData, dataFilterFieldConfig);
    } else {
      return filterListByApplicationName(rowListData, filterDataByApplicationName ?? '')
    }
  }, [filterDataByApplicationName, dataFilterFieldConfig, rowListData]);

  const updateSelectedRows = useCallback(() => {
    if (selectedRows?.length > 0) {
      const rowId = gridItemId ?? '';

      gridApi.forEachNode(function (node: IRowNode<any>) {
        if (selectedRows.find((item) => node.data[rowId] === item[rowId])) {
          node.setSelected(true);
        }
      });
    }
  }, [rowListData]);

  useEffect(() => {
    if (getConfigList && rowListData.length < 1) {
      dispatch(getConfigList());
    }

    const filterConfig = getCachedTableFilterData(`${listName}`);
    if (!filterDataByApplicationName && !dataFilterFieldConfig && !filterConfig?.searchValue && filterConfig?.appliedFilters && Object.keys(filterConfig.appliedFilters).length < 1) {
      setKeyDataInLocalStorage(CONFIG_TABLE_SEARCH_FILTER_KEY, {});
    }
  }, []);

  return (
    <ConfigurationListTableWrapper>
      <CommonTableComponent
        headerRightMenuActions={
          <TableHeaderActions
            {...(createBtnClass && { createBtnClass: selectedRows.length < 1 ? `${createBtnClass} btn-disabled` : createBtnClass })}
            creatBtnLabel={creatBtnLabel}
            deleteBtnLabel={deleteBtnLabel}
            onClose={onClose}
            onCreate={onCreateHandler}
            onDelete={onDeleteHandler}
            onExport={onExportData}
            onImport={onImport}
            onModify={onMultipleModifyHandler}
            isExportDisabled={currentRowCount < 1}
            hasClose={hasClose}
            hasCreate={hasCreate && userAccessInfo?.adminAccess?.toUpperCase() === 'Y'}
            hasDelete={hasHeaderDelete}
            hasImport={hasImport}
            hasExport={hasExport}
            hasModify={userAccessInfo?.adminAccess?.toUpperCase() === 'Y' && rowSelection === 'multiple' && selectedRows.length > 1}
            isDeleteDisabled={selectedRows.length < 1}
          />
        }
        filterBy={filterBy ?? {
          fieldName: 'applicationName',
          fieldLabel: 'Application'
        }}
        {...(!filterBy && { filterDataList: applicationDataHelper.getApplicationNames([]) })}
        rowData={rowData ?? ((filterDataByApplicationName !== undefined || dataFilterFieldConfig !== undefined) ? filteredDataByField : rowListData)}
        columnDefs={hasRowAction && userAccessInfo?.adminAccess?.toUpperCase() === 'Y'
          ? [...columnDefs, {
              ...{
                ...ROW_ACTION_COLUMN,
                ...(className === 'right-side-actions-small' && {
                  width: 100
                })
              },
              cellRendererParams: {
                ...ROW_ACTION_COLUMN.cellRendererParams,
                onAuditSummary: onAuditSummaryBtnHandler,
                onDelete: onDeleteHandler,
                isEditDisabled: selectedRows.length > 0,
                isDeleteDisabled: selectedRows.length > 0,
                hasAuditSummary,
                hasEdit,
                hasDelete,
                gridRowId,
                onEdit,
                gridName
              }
            }]
          : columnDefs
        }
        onRowDataChange={(count: number) => setCurrentRowCount(count)}
        {...(rowSelection === 'multiple' && {
          onSelectionChanged: (params: SelectionChangedEvent) => {
            if (params?.api) {
              setSelectedRows(params?.api?.getSelectedRows());
            }
          }
        })}
        allowSearchFilterCache={allowSearchFilterCache}
        getRowId={getRowId}
        onGridReady={(params: any) => setGridApi(params.api)}
        className={`${String(className)} ${hideRowCheckbox && userAccessInfo?.adminAccess?.toUpperCase() !== 'Y' ? 'hide-row-selection' : ''} primary-theme`}
        tableHeaderClass="config-list-grid"
        rowSelection={rowSelection}
        rowMultiSelectWithClick={rowMultiSelectWithClick}
        showSearchBar={showSearchBar}
        showResultsCount={showResultsCount}
        showHeaderFilterButton={hasFilter}
        onRowDoubleClicked={onRowDoubleClicked}
        onUpdateRowSelection={updateSelectedRows}
        hasDefaultSearchFilter={hasDefaultSearch}
        height={height ?? '100%'}
        searchKeyName={listName}
        isDataLoading={isDataLoading}
        isApplicationFilter={!filterBy}
        isFilterColDefaultChecked={!!filterBy}
        accentedSort
        hasTotalCount
        isColSizeAutoFit
      />
      {isConfigHistoryPopupOpen && (
        <ConfigAuditHistoryDrawer
          configName={gridName ?? ''}
          configId={'test'}
          onCancel={onConfigAuditHistoryCancel}
        />
      )}
    </ConfigurationListTableWrapper>
  );
};

ConfigurationListTable.defaultProps = {
  hasAuditSummary: false,
  hasCustomAdd: false,
  hasEdit: true,
  hasFilter: true,
  hasDelete: true,
  hasCustomDelete: false,
  hasDefaultSearch: false,
  hideRowCheckbox: false,
  gridName: 'Configuration',
  showResultsCount: true,
  showSearchBar: true,
  gridRowId: 'applicationName'
};

ConfigurationListTable.propTypes = {
  allowSearchFilterCache: PropTypes.bool,
  createBtnClass: PropTypes.string,
  creatBtnLabel: PropTypes.string,
  columnDefs: PropTypes.array.isRequired,
  dataFilterFieldConfig: PropTypes.object,
  deleteBtnLabel: PropTypes.string,
  hasAuditSummary: PropTypes.bool,
  hideRowCheckbox: PropTypes.bool,
  getConfigList: PropTypes.func,
  getRowId: PropTypes.func,
  gridName: PropTypes.string,
  gridItemId: PropTypes.string,
  hasDefaultSearch: PropTypes.bool,
  listName: PropTypes.string.isRequired,
  rowData: PropTypes.array,
  rowSelection: PropTypes.string,
  rowMultiSelectWithClick: PropTypes.bool,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onExport: PropTypes.func,
  onImport: PropTypes.func,
  onMultipleModify: PropTypes.func,
  hasClose: PropTypes.bool,
  hasCreate: PropTypes.bool,
  hasCustomAdd: PropTypes.bool,
  hasCustomDelete: PropTypes.bool,
  hasDelete: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasHeaderDelete: PropTypes.bool,
  hasFilter: PropTypes.bool,
  hasImport: PropTypes.bool,
  hasExport: PropTypes.bool,
  hasRowAction: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  onRowDoubleClicked: PropTypes.func,
  showSearchBar: PropTypes.bool,
  showResultsCount: PropTypes.bool,
  height: PropTypes.string,
  className: PropTypes.string,
  filterDataByApplicationName: PropTypes.string,
  gridRowId: PropTypes.string
};

export default ConfigurationListTable;
