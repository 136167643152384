import React from 'react';
import type { FC } from 'react';
import JsonView from 'react18-json-view';

interface JSONViewRendererProps {
  value?: any
}

const JSONViewRenderer: FC<JSONViewRendererProps> = ({ ...params }) => {
  if (!params.value) {
    return null;
  }

  return (
    <JsonView
      enableClipboard={false}
      src={JSON.parse(JSON.stringify(params.value))}
      collapsed
    />
  );
};

export default JSONViewRenderer;
