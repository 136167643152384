import React from 'react';
import type { FC } from 'react';
import { CONFIGURATION_AUDIT_HISTORY_COLUMN_CONFIG } from '../../../constants/TableColumns/Admin/ConfigurationAuditHistory';
import AuditHistorySearchPanel from '../../../components/AdditionalComponents/ConfigurationAuditHistory/AuditHistorySearchPanel';
import ConfigurationListTable from '../../../components/AdditionalComponents/ConfigurationListTable';
import { CONFIGURATION_AUDIT_HISTORY_SEARCH } from '../../../constants/breadCrumbTextConstants';
import { AdminMainBreadCrumbWrapper, AdminMainLayoutWrapper } from '../../../assets/styles/commonStyled';
import { ConfigAuditHistoryWrapper } from './style';

const ConfigAuditHistorySearch: FC = () => {
  return (
    <AdminMainLayoutWrapper data-testid="configAuditHistorySearchWrapper">
      <AdminMainBreadCrumbWrapper>
        {CONFIGURATION_AUDIT_HISTORY_SEARCH}
      </AdminMainBreadCrumbWrapper>
      <ConfigAuditHistoryWrapper>
        <AuditHistorySearchPanel onSubmit={() => console.log('data')}/>
        <ConfigurationListTable
          rowData={[]}
          columnDefs={CONFIGURATION_AUDIT_HISTORY_COLUMN_CONFIG}
          getConfigList={null}
          listName="auditHistory"
          hasFilter={false}
        />
      </ConfigAuditHistoryWrapper>
    </AdminMainLayoutWrapper>
  );
};

export default ConfigAuditHistorySearch;
