import JSONViewRenderer from '../customRendererForColumns/JSONViewRenderer';

export const CONFIGURATION_AUDIT_HISTORY_COLUMN_CONFIG = [
  {
    field: 'action',
    headerName: 'Action',
    sortable: true,
    filter: true,
    flex: 1
  },
  {
    field: 'newValue',
    headerName: 'Updated Value',
    flex: 2,
    wrapText: true,
    autoHeight: true,
    cellRenderer: JSONViewRenderer
  },
  {
    field: 'oldValue',
    headerName: 'Previous Value',
    flex: 2,
    wrapText: true,
    autoHeight: true,
    cellRenderer: JSONViewRenderer
  },
  {
    field: 'actionPerformedDate',
    headerName: 'Action Performed Date',
    flex: 1
  },
  {
    field: 'actionTakenBy',
    headerName: 'Action Taken By',
    sortable: true,
    flex: 1
  }
];
