import RowActionButtonsRenderer from './customRendererForColumns/RowActionButtonsRenderer';

export const ROW_ACTION_COLUMN: any = {
  field: 'row_action_button',
  colId: 'actionCol',
  headerName: 'Action',
  width: 140,
  pinned: 'right',
  lockPosition: 'right',
  suppressMovable: true,
  suppressCellFocus: true,
  resizable: false,
  cellRenderer: RowActionButtonsRenderer,
  cellRendererParams: {
    hasDelete: true,
    hasEdit: true
  },
  onCellClicked: (event: any) => {
    event?.node?.setSelected(false);
  }
};
