import React, { useMemo, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, DatePicker, Form, Row, Select } from 'antd';
import { useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import MultiSelect from '../../../common/MultiSelect';
import {
  blobMetricTypeOptions,
  blobMetricsSummaryDateRangeOptions
} from '../../../../constants/DropDownOptions/adminConfig';
import { BlobMetricSearchPanelWrapper } from './style';
import { applicationDataHelper } from '../../../../helpers/applicationDataHelper';
import { disableFutureDateSelection } from '../../../../helpers/commonHelper';
import { SearchButton } from '../../LogException/LogExceptionSearchPanel/style';

interface BlobMetricSearchPanelProps {
  onFilterChange: (data: any[], filterType: string) => void
  onDateRangeChange?: any
  onSearchSubmitHandler?: any
  hasDatePicker?: boolean
  hasSubmitBtn?: boolean
  isMultiSelectAllowed?: boolean
}

const BlobMetricSearchPanel: FC<BlobMetricSearchPanelProps> = ({
  hasDatePicker,
  hasSubmitBtn,
  isMultiSelectAllowed,
  onFilterChange,
  onDateRangeChange,
  onSearchSubmitHandler
}) => {
  const [form] = Form.useForm();
  const applicationList = useSelector(
    (state: any) => state.applicationConfig.applicationConfigList
  );

  const applicationNameList = useMemo(
    () => applicationDataHelper.getApplicationNames(applicationList),
    [applicationList]
  );
  const applicationName = Form.useWatch('application', form);

  const [selectedApplications, setSelectedApplications] = useState(applicationNameList);

  const onApplicationNameChange = (selectedAppList: Array<Record<string, any>>): void => {
    setSelectedApplications(selectedAppList);
    onFilterChange(selectedAppList, '');
  };

  const onFormSubmit = (): void => {
    form
      .validateFields()
      .then((values) => {
        values = {
          application: isMultiSelectAllowed
            ? values.application
            : values.application[0].value,
          date: dayjs(values.date).format('YYYY-MM-DD')
        };
        onSearchSubmitHandler(values);
      })
      .catch((e) => console.log('error', e));
  };

  return (
    <BlobMetricSearchPanelWrapper>
      <Form
        form={form}
        onFinish={onFormSubmit}
        data-testid="searchPanelForm"
        initialValues={{
          ...(isMultiSelectAllowed && { application: selectedApplications }),
          ...(hasDatePicker && { date: dayjs() }),
          type: ['Log', 'Exception'],
          searchByType: 'Component',
          quickTime: 24
        }}
      >
        <Row>
          <Col flex="auto">
            <Form.Item
              name="application"
              noStyle
            >
              <MultiSelect
                dropdownPanelSize={!isMultiSelectAllowed ? 'default' : 'large'}
                fieldName="Application"
                placeholder={isMultiSelectAllowed ? 'Filter by Application' : 'Select Application'}
                id="applicationName"
                {...(isMultiSelectAllowed && {
                  onChange: onApplicationNameChange,
                  value: selectedApplications
                })}
                options={isMultiSelectAllowed ? applicationNameList : [{ label: 'All', value: '' }, ...applicationNameList]}
                searchInputPlaceholder="Search Application Name"
                hasSelectAll={isMultiSelectAllowed}
                multi={isMultiSelectAllowed}
                visibleTagCount={5}
                isDefaultAllItemSelected={isMultiSelectAllowed}
                allowClear={isMultiSelectAllowed}
                clearOnSelect
                searchable
              />
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item name="type" noStyle>
              <Select
                onChange={(data: any[]) => onFilterChange(data, 'blobMetricType')}
                options={blobMetricTypeOptions}
                showSearch={false}
                mode="multiple"
                data-testid="blobMetricTypeInput"
              />
            </Form.Item>
          </Col>
          <Col md={4}>
            {!hasDatePicker && (
              <Select
                onChange={onDateRangeChange}
                options={blobMetricsSummaryDateRangeOptions}
                showSearch={false}
                data-testid="dateRangeInput"
                defaultValue="daily"
              />
            )}
            {hasDatePicker && (
              <Form.Item name="date" noStyle>
                <DatePicker
                  allowClear={false}
                  disabledDate={disableFutureDateSelection}
                />
              </Form.Item>
            )}
          </Col>
          {hasSubmitBtn && (
            <Col>
              <SearchButton
                type="primary"
                data-testid="submitSearchBtn"
                htmlType="submit"
                icon={<SearchOutlined />}
                disabled={!applicationName || applicationName.length < 1}
              />
            </Col>
          )}
        </Row>
      </Form>
    </BlobMetricSearchPanelWrapper>
  );
};

BlobMetricSearchPanel.defaultProps = {
  hasDatePicker: false,
  hasSubmitBtn: false,
  isMultiSelectAllowed: true
};

BlobMetricSearchPanel.propTypes = {
  hasDatePicker: PropTypes.bool,
  hasSubmitBtn: PropTypes.bool,
  isMultiSelectAllowed: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
  onDateRangeChange: PropTypes.func,
  onSearchSubmitHandler: PropTypes.func
};

export default BlobMetricSearchPanel;
