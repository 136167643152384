import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { columnAlertFormBasicFields, columnAlertFormIfFields, columnAlertFormThenFields, columnAlertFormNotificationFields } from '../../../../../constants/FormFields/AdminForm/columnAlertFormFields';
import { Col, Row } from 'antd';
import { ApplicationFormItemsLayout } from '../../../../../constants/FormLayout/ApplicationFormLayout';
import { type FormFieldType } from '../../../../../constants/Interfaces/FormFieldTypes';
import { LabelWrapper, LabelValueWrapper, RowCriteriaWithBorderWrapper, RowCriteriaWithOutBorderWrapper } from '../../../../../assets/styles/commonStyled';
import textConstants from '../../../../../constants/textConstants';
import { SummaryMainWrapper } from '../../style';
import { findLabelByValue } from '../../../../../helpers/commonHelper';
import { columnAlertColumnNameOptions, snowServiceNameOptions } from '../../../../../constants/DropDownOptions/adminConfig';

interface componentProps {
  data?: any
}
const columnSpan = ApplicationFormItemsLayout.columnSpan
const ColumnAlertConfigDetailSummary: FC<componentProps> = ({ data }) => {
  const { enableSnowNotificationField, sysClassNameField, snowParameterField, enableGroupNotificationField, notificationGroupField, enableMailNotificationField, emailAddressField } = columnAlertFormNotificationFields;

  const getNotificationRadioTextValue = (notificationVal: number): string => {
    if (notificationVal && notificationVal === 1) {
      return 'Yes';
    }
    return 'No';
  }

  return (
        <SummaryMainWrapper>
            <Row gutter={ApplicationFormItemsLayout.rowGutter} data-testid="testid-column-alert-config-detail" >
                {
                    columnAlertFormBasicFields.map((formField: FormFieldType) => {
                      return (
                            <Col key={formField.id} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                <LabelWrapper>{formField.label}</LabelWrapper>
                                <LabelValueWrapper>
                                    {data?.[formField.name] ?? 'N/A'}
                                </LabelValueWrapper>
                            </Col>);
                    })
                }
            </Row>
            <RowCriteriaWithBorderWrapper
                gutter={ApplicationFormItemsLayout.rowGutter}
                className='conditional-row'
                data-title={textConstants.COLUMN_ALERT_CONFIG_DETAIL_IF_CRITERIA_LABEL}>
                {
                    columnAlertFormIfFields.map((formField: FormFieldType) => {
                      return (
                            <Col key={formField.id} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                <LabelWrapper>{formField.label}</LabelWrapper>
                                <LabelValueWrapper>
                                    {data?.[formField.name] ?? 'N/A'}
                                </LabelValueWrapper>
                            </Col>);
                    })
                }
            </RowCriteriaWithBorderWrapper>
            <RowCriteriaWithOutBorderWrapper
                gutter={ApplicationFormItemsLayout.rowGutter}
                data-title={textConstants.COLUMN_ALERT_CONFIG_DETAIL_THEN_CRITERIA_LABEL}
                className='conditional-row'
            >
                {
                    columnAlertFormThenFields.map((formField: any) => {
                      return (
                            <Col key={formField.id} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                <LabelWrapper>{formField.label}</LabelWrapper>
                                <LabelValueWrapper>
                                    {
                                        formField.type === 'select'
                                          ? findLabelByValue(columnAlertColumnNameOptions, data?.[formField.name])
                                          : data?.[formField.name] ?? 'N/A'
                                    }
                                </LabelValueWrapper>
                            </Col>);
                    })
                }
            </RowCriteriaWithOutBorderWrapper>

            <RowCriteriaWithOutBorderWrapper style={{ display: 'block' }}
                className='conditional-row notification-main-row-wrapper'
                data-title={textConstants.COLUMN_ALERT_CONFIG_NOTIFICATION_DETAIL_CRITERIA_LABEL} >
                <Row>
                    <Col key={enableSnowNotificationField.id} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <LabelWrapper>{enableSnowNotificationField.label}</LabelWrapper>
                        <LabelValueWrapper className='blue-theme'>{getNotificationRadioTextValue(data?.[enableSnowNotificationField.name])}</LabelValueWrapper>
                    </Col>
                    {
                        data?.[enableSnowNotificationField.name] === 1 &&
                        <>
                            <Col key={sysClassNameField.id} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                <LabelWrapper>{sysClassNameField.label}</LabelWrapper>
                                <LabelValueWrapper>{findLabelByValue(snowServiceNameOptions, data?.[sysClassNameField.name])}</LabelValueWrapper>
                            </Col>
                            <Col key={snowParameterField.id} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                <LabelWrapper>{snowParameterField.label}</LabelWrapper>
                                <LabelValueWrapper>{data?.[snowParameterField.name]}</LabelValueWrapper>
                            </Col>
                        </>
                    }
                </Row>
                <Row>
                    <Col key={enableGroupNotificationField.id} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <LabelWrapper>{enableGroupNotificationField.label}</LabelWrapper>
                        <LabelValueWrapper className='blue-theme'>{getNotificationRadioTextValue(data?.[enableGroupNotificationField.name])}</LabelValueWrapper>
                    </Col>
                    {
                        data?.[enableGroupNotificationField.name] === 1 &&
                        <>
                            <Col key={notificationGroupField.id} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                <LabelWrapper>{notificationGroupField.label}</LabelWrapper>
                                <LabelValueWrapper>{data?.[notificationGroupField.name]}</LabelValueWrapper>
                            </Col>
                        </>
                    }
                </Row>
                <Row>
                    <Col key={enableMailNotificationField.id} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                        <LabelWrapper>{enableMailNotificationField.label}</LabelWrapper>
                        <LabelValueWrapper className='blue-theme'>{getNotificationRadioTextValue(data?.[enableMailNotificationField.name])}</LabelValueWrapper>
                    </Col>
                    {
                        data?.[enableMailNotificationField.name] === 1 &&
                        <>
                            <Col key={emailAddressField.id} xs={columnSpan.xs} md={columnSpan.md} xxl={columnSpan.xxl}>
                                <LabelWrapper>{emailAddressField.label}</LabelWrapper>
                                <LabelValueWrapper>{data?.[emailAddressField.name]}</LabelValueWrapper>
                            </Col>
                        </>
                    }
                </Row>
            </RowCriteriaWithOutBorderWrapper>
        </SummaryMainWrapper>
  );
};

ColumnAlertConfigDetailSummary.propTypes = {
  data: PropTypes.object
};

export default ColumnAlertConfigDetailSummary;
