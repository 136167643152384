import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, DatePicker, Row, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { disableFutureDateSelection } from '../../../../helpers/commonHelper';
import { auditConfigTypeOptions } from '../../../../constants/DropDownOptions/adminConfig';
import { SearchButton } from '../../LogException/LogExceptionSearchPanel/style';
import { AuditHistorySearchPanelWrapper } from './style';

interface AuditHistorySearchPanelProps {
  onSubmit: any
}

const AuditHistorySearchPanel: FC<AuditHistorySearchPanelProps> = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const configName = Form.useWatch('configName', form);
  const dateRange = Form.useWatch('dateRange', form);

  const onFormSubmit = (): void => {
    form
      .validateFields()
      .then((values) => {
        onSubmit(values);
      })
      .catch((e) => console.log('error', e));
  };

  return (
    <AuditHistorySearchPanelWrapper>
      <Form
        form={form}
        onFinish={onFormSubmit}
        data-testid="searchPanelForm"
      >
        <Row>
          <Col flex="auto">
            <Form.Item
              name="configName"
            >
              <Select
                options={auditConfigTypeOptions}
                placeholder='Select Config Name'
              />
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item
              name="dateRange"
              noStyle
            >
              <DatePicker.RangePicker
                allowClear={false}
                disabledDate={disableFutureDateSelection}
                presets={[
                  { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
                  { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
                  { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] }
                ]}
              />
            </Form.Item>
          </Col>
          <Col>
            <SearchButton
              type="primary"
              data-testid="submitSearchBtn"
              htmlType="submit"
              icon={<SearchOutlined />}
              disabled={!configName || !dateRange}
            />
          </Col>
        </Row>
      </Form>
    </AuditHistorySearchPanelWrapper>
  );
};

AuditHistorySearchPanel.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default AuditHistorySearchPanel;
