import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import { CONFIGURATION_AUDIT_HISTORY_COLUMN_CONFIG } from '../../../../constants/TableColumns/Admin/ConfigurationAuditHistory';
import ConfigurationListTable from '../../ConfigurationListTable';

interface ConfigAuditHistoryDrawerProps {
  configName: string
  configId: string
  onCancel: () => void
}

const ConfigAuditHistoryDrawer: FC<ConfigAuditHistoryDrawerProps> = ({
  configName,
  configId,
  onCancel
}) => {
  const [configAuditHistoryData, setConfigAuditHistoryData] = useState<any>([]);
  const [isConfigAuditDrawerOpen, setConfigAuditDrawerState] = useState<boolean>(false);

  const onConfigAuditHistoryDrawerClose = (): void => {
    setConfigAuditDrawerState(false);
    onCancel();
  }

  useEffect(() => {
    if (configId && configName) {
      setConfigAuditHistoryData([
        {
          action: 'Update',
          newValue: {
            description: 'test'
          },
          oldValue: {
            description: 'test'
          },
          actionPerformedDate: '20-07-2024',
          actionTakenBy: 'John'
        },
        {
          action: 'Create',
          newValue: {
            description: 'test'
          },
          oldValue: {
            description: 'test'
          },
          actionPerformedDate: '20-06-2024',
          actionTakenBy: 'Steve'
        }
      ]);
      setConfigAuditDrawerState(true);
    }
  }, [configId, configName]);

  return (
    <Drawer
      placement="right"
      onClose={onConfigAuditHistoryDrawerClose}
      getContainer={false}
      width="100%"
      data-testid='configAuditHistory'
      open={isConfigAuditDrawerOpen}
      rootClassName='add-edit-config-drawer config-audit-history-drawer'
      title={`${configName} Audit History`}
    >
      <ConfigurationListTable
        rowData={configAuditHistoryData}
        columnDefs={CONFIGURATION_AUDIT_HISTORY_COLUMN_CONFIG}
        getConfigList={null}
        listName="auditHistory"
        filterBy={{
          fieldName: 'action',
          fieldLabel: 'Action'
        }}
      />
    </Drawer>
  )
}

ConfigAuditHistoryDrawer.propTypes = {
  configId: PropTypes.string.isRequired,
  configName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default ConfigAuditHistoryDrawer;
