import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import routeConstants from '../../../constants/routeConstants';

interface componentProps {
  isOpen?: boolean
  closeModal?: () => void
  logExceptionType?: string
}

const NavigateToSavedSearchModal: FC<componentProps> = ({ isOpen, closeModal, logExceptionType }) => {
  const history = useHistory();
  const okHandler = (): void => {
    history.push(logExceptionType?.toLowerCase() === 'log' ? routeConstants.LOG_SAVED_SEARCH : routeConstants.EXCEPTION_SAVED_SEARCH)
  }
  return <>
    <Modal
      data-testid='test-id-navigate-to-saved-search-modal'
      open={isOpen}
      title="Limit is exceeding"
      onCancel={closeModal}
      okText="Navigate to Saved Search"
      onOk={okHandler}
    >
      Please delete any one saved search criteria to save new one!
    </Modal >

  </>;
};

NavigateToSavedSearchModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  logExceptionType: PropTypes.string
};

export default NavigateToSavedSearchModal
