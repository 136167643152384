import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { AdminMainLayoutWrapper } from '../../../../assets/styles/commonStyled';
import ConfigDetailCardHeader from '../../../../components/AdditionalComponents/ConfigDetailCardHeader';
import textConstants from '../../../../constants/textConstants';
import ColumnAlertConfigForm from '../../../../components/AdditionalComponents/UpsertForms/Admin/ColumnAlertConfig';
import { useDispatch } from 'react-redux';
import { deleteColumnAlertConfig, updateColumnAlertConfig } from '../../../../redux/actions/Admin/columnAlertConfigActions';
import ColumnAlertConfigDetailSummary from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/ColumnAlertConfig/ColumnAlertConfigDetailSummary';
import AdminDetailBreadCrumb from '../../../../components/common/BreadCrumb/AdminDetailBreadCrumb';
import { COLUMN_ALERT_MGMNT_DETAIL_TEXT } from '../../../../constants/breadCrumbTextConstants';
import { ConfigDetailMainWrapper, ConfigDetailWrapper } from '../../../../components/AdditionalComponents/DetailSummaryPage/Admin/style';
import { getEditDataChanges } from '../../../../helpers/commonHelper';

interface componentProps {
  prop1?: string
}

const ColumnAlertConfigDetail: FC<componentProps> = ({ prop1 }) => {
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();
  const { data, isEditable, isFromApplicationConfig } = location?.state as any

  const [isDetailInEditMode, setDetailInEditMode] = useState(isEditable ?? false);
  const [configData, setConfigData] = useState(data);

  const onFormSaveCancelHandler = (): void => {
    if (isEditable) {
      history.goBack();
    } else {
      setDetailInEditMode(false);
    }
  }

  const onSaveHandler = (formData: any): void => {
    const updatedPayload = getEditDataChanges(configData, formData);

    if (updatedPayload) {
      dispatch(updateColumnAlertConfig(updatedPayload));
      setConfigData({
        ...data,
        ...formData
      });
    }
    onFormSaveCancelHandler();
  }

  const onDeleteHandler = (): void => {
    dispatch(deleteColumnAlertConfig([configData], history.goBack))
  }
  return (
    <AdminMainLayoutWrapper data-testid="testid-column-alert-config">
      <AdminDetailBreadCrumb
        isFromApplicationConfig={isFromApplicationConfig}
        mainPageText={COLUMN_ALERT_MGMNT_DETAIL_TEXT}
        currentPageText={data?.applicationName}
      />
      <ConfigDetailMainWrapper hasBottomGap={isDetailInEditMode}>
        <ConfigDetailWrapper>
          <ConfigDetailCardHeader
            onDelete={onDeleteHandler}
            onEdit={() => setDetailInEditMode(true)}
            isEditEnabled={!isDetailInEditMode}
            title={textConstants.COLUMN_ALERT_CONFIG_DETAIL_PAGE_TITLE}
            configName={textConstants.COLUMN_ALERT_CONFIG_TITLE}
          />
          {
            isDetailInEditMode &&
            <ColumnAlertConfigForm onSave={onSaveHandler} data={configData}
              onCancel={onFormSaveCancelHandler} />
          }
          {
            !isDetailInEditMode &&
            <ColumnAlertConfigDetailSummary data={configData} />
          }
        </ConfigDetailWrapper>
      </ConfigDetailMainWrapper>
    </AdminMainLayoutWrapper>
  );
};

ColumnAlertConfigDetail.propTypes = {
  prop1: PropTypes.string
};

export default ColumnAlertConfigDetail;
